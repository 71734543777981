@import '../variables'
@import '../mixins/anchor'

$basePadding: 10px

.content-heading__separator
  &:before
    content: "|"
    opacity: 0.5

.post_likes_counter
  display: flex
  gap: $basePadding
  &:empty
    display: none

.content-heading__item
  display: flex
  flex-wrap: wrap
  gap: $basePadding
  line-height: 1.6em
  margin-bottom: $basePadding
  margin-right: $basePadding
  .content-heading__fa-icon
    margin-right: 4px
    color: $grey300
    font-size: $base-font-size
  .content-heading__author-name
    +anchor-naked-link-style   

.full_width_post
  .content-heading__primary,
  .content-heading__item
    justify-content: center
    text-align: center

.content-heading__separator-first
  +mobile-and-tablet
    display: none

.content-heading__read-time-label
  +mobile
    display: none

.content-heading
  margin-bottom: 32px

.content-heading,
.post__header-hero-image
  font-size: $base-min-font-size
  .content-heading__primary
    max-width: 850px
    padding-bottom: 32px
    border-bottom: 1px solid $borderColor
    margin: auto
    font-size: 14px
    display: flex
    flex-wrap: wrap
    +mobile
      display: block
      padding-bottom: 24px
      .content-heading__item
        align-items: baseline
      .content-heading__author-name
        margin-right: 0
        margin-left: 0
      select
        background-color: transparent
        border: none
        &:focus
          outline: none
        option
          background-color: transparent
      select
        background-color: transparent !important
        border: none
        &:focus
          outline: none
        option
          background-color: transparent !important

    .content-heading__item
      +tablet
        width: 100%

    .content-heading__item
      align-items: center
      .content-author__image
        width: 30px
        height: 30px
        margin: 0
        +mobile
          display: none
      select.content-heading__value
        font-family: $bodyFont
      .content-heading__value
        font-family: $bodyFont
        color: $newBodyTextColor
        font-size: 14px
      .content-heading__label
        color: $grey500

.course,
.lesson
  .content-heading
    .content-heading__primary
      max-width: 100%
      .content-heading__item
        +tablet
          justify-content: center
        .content-heading__fa-icon
          margin-right: 6px
      .content-heading__length-label
        +mobile
          display: none
    .content-heading__secondary
      +tablet
        justify-content: center

.course-banner
  .content-banner__body,
  .content-banner__content-breadcrumb
    +tablet
      display: flex
      flex-wrap: wrap
      justify-content: center
      text-align: center

.content-heading__author-bio-and-meta-info
  display: flex
  justify-content: space-between

  +mobile
    flex-direction: column
    border-bottom: none

  .content-heading__author-bio
    display: flex
    align-items: center
    margin-right: 40px

    .content-author__image
      width: 40px
      height: 40px
      border-radius: 6px
      margin: 0px 16px 0px 0px
      
      +mobile
        display: none

    .content-heading__author-name-publish-date-and-read-time
      display: flex
      flex-direction: column
      justify-content: space-between
      height: 100%

      .content-heading__author-name
        +anchor-naked-link-style
        line-height: 18px

      .content-heading__publish-date-and-read-time
        line-height: 20px

        .content-heading__separator
          color: $grey500 !important
          &:before
            display: none
            content: ""

  .content-heading__meta-info
    display: flex
    flex-direction: row

    +mobile
      margin-top: 16px

    .content-heading__meta-info-item
      display: flex
      align-items: center
      margin-left: 8px
      margin-right: 8px
      height: 40px
      padding: 10px 12px
      border-radius: 6px
      border: 1px solid $grey100
      font-size: 14px
      line-height: 20px
      font-weight: 600
      color: $grey700

      &:first-child
        margin-left: 0px !important

      .content-heading__meta-info-likes-icon
        margin-left: 6px

.content-heading__meta-info-language-selector-icon
  padding-right: 8px
  padding-top: 4px

.content-heading__meta-info-language-selector
  position: relative
  display: flex
  align-items: center
  margin-right: 25px

  .language-selector-wrapper
    position: unset !important
    > a
      display: flex
      align-items: center
      height: 40px
      padding: 10px 12px
      border-radius: 6px
      border: 1px solid $grey100
      font-size: 14px
      line-height: 20px
      font-weight: 600
      color: $grey700

    > ul
      top: 40px

.post__header-hero-image-below-text-mobile
  +mobile
    .content-heading__author-bio-and-meta-info
      justify-content: center
      .content-heading__author-bio
        justify-content: center
        display: flex
        margin-right: 0
        .content-heading__author-name
          display: flex
          justify-content: center

.post__header-hero-image-below-text
  .content-heading__author-bio-and-meta-info
    justify-content: center

.full_width_post
  .content-heading__author-bio-and-meta-info
    justify-content: center

    +mobile
      flex-direction: column

      .content-heading__author-bio
        margin-right: 0px
        justify-content: center

        .content-heading__author-name
          display: flex
          justify-content: center

      .content-heading__meta-info
        justify-content: center

.post__header-hero-image-behind-text
  +non-mobile
    .content-heading__author-bio-and-meta-info
      justify-content: center
      color: $white

      .content-heading__separator,
      .content-heading__meta-info-likes
        color: $white

    .content-heading__meta-info-language-selector-icon path,
    .content-heading__meta-info-likes-icon path
      fill: $white

    .language-selector-wrapper
      > a,
      > i
        color: $white

.languges-and-sharing-component,.sharing-component
  display: flex 

  .content-heading__social_share
    display: flex
    justify-content: space-between

    .share-icon 
      margin: 3px
      width: 40px
      height: 40px
      display: flex
      align-items: center
      justify-content: center
      border: 1px solid #CCCCCC
      border-radius: 50%

      a
        width: 100%
        height: 100%
        display: flex
        position: relative
        flex-direction: column

        svg
          position: absolute
          top: 8px
          right: 8px

    .social-link 
      width: 40px
      height: 40px
      border-radius: 50%

    .social-icon 
      width: 22px
      height: 22px
      fill: $social_icon_default_fill

    .social-link:hover .social-icon-fb 
      fill: $social_icon_fb_fill

    .social-link:hover .social-icon-x 
      fill: $social_icon_x_fill

    .social-link:hover .social-icon-pinterest 
      fill: $social_icon_pinterest_fill

    .social-link:hover .social-icon-linkedin 
      fill: $social_icon_linkedin_fill

    .social-link:hover .social-icon-link 
      fill: $social_icon_link_fill
    
    .social-link:hover .social-icon-reddit
      fill: $social_icon_reddit_fill

    #toast 
      visibility: hidden
      min-width: 250px
      margin-left: -125px
      background-color: #333
      color: #fff
      text-align: center
      border-radius: 2px
      padding: 16px
      position: fixed
      z-index: 1
      left: 50%
      bottom: 30px
      font-size: 17px

    #toast.show 
        visibility: visible
        animation: fadein 0.5s, fadeout 0.5s 2.5s
