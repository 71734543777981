@import '../../variables'

.content-block-detailedinterviewblockquote
  border-left: 4px solid $primaryBlueColor
  padding: 16px 0px 16px 32px
  margin-bottom: 40px
  .detailed-interview_quote
    font-size: 24px
    margin-bottom: 32px
    line-height: 150%
    letter-spacing: 0.36px
    p
      margin: 0px
  .detailed-interview-blockquote__container
    display: flex
    .detailed-interview-blockquote__profile-photo
      margin-right: 20px
      img
        width: 60px
        border-radius: 50%
        padding: 0 !important
        margin: 0 !important
    .detailed-interview-blockquote__profile-info
      flex-grow: 1

.detailed-interview_profile__name
  font-size: 14px
  margin-top: -6px
  margin-bottom: 0px
  line-height: 150%
  letter-spacing: -0.154px

  strong
    line-height: 125%
