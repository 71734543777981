@import '../../variables'

.hr-line
  display: block
  margin-top: 26px !important
  margin-bottom: 26px !important
  height: 2px
  border-width: 0px
  background: rgb(209, 213, 219)
  display: block !important
  min-height: 0px !important
  padding: 0 !important