@import '../../variables'

.content-block-button
  display: flex
  width: max-content
  text-align: center
  background-color: $callToActionButtonBackground
  border-radius: 4px
  a
    font-weight: 700
    color: $white
    text-decoration: none
    padding: 8px 24px
    &:hover, &:active, &:focus
      color: $white
  &:hover, &:active, &:focus
    background-color: $callToActionButtonBackgroundHover
