@import '../../variables'

.content-block-socialgroup
  display: flex
  row-gap: 8px
  flex-wrap: wrap

.social-element--envato-icon
  content: url('images/envato_leaf_gray.svg')

.social-element--envato-icon:hover
  content: url('images/envato_leaf_blue.svg')

.social-element
  margin-right: 32px
  .social-element--profile-handle-name
    padding-left: 4px
    font-weight: 600
  .social-element--icon
    color: $primaryBlueColor
  .social-element--link
    text-decoration: none
    color: $grey700
    display: flex
    align-items: center
    &:hover
      .social-element--profile-handle-name
        text-decoration: underline
        text-decoration-skip-ink: none
