@import '../../variables'

$baseThumbnailDesktopHeight: 70px
$baseThumbnailMobileHeight: 50px

.image-gallery_thumbnail-container
  margin-top: 10px
  .image-gallery-thumbnail_preview
    max-width: 110px !important
    margin-right: 10px
    padding: 3px
    cursor: pointer
    max-height: $baseThumbnailDesktopHeight + 4px
    +mobile
      width: 80px !important
      max-height: $baseThumbnailMobileHeight + 4px
    img
      border-radius: 6px
      padding: 0
      margin: 0
      max-height: $baseThumbnailDesktopHeight
      +mobile
        max-height: $baseThumbnailMobileHeight
  .slick-list
    .slick-track
      margin: 0 !important
      .slick-slide
        width: 120px
        +mobile
          width: 90px
        &.slick-current
          .image-gallery-thumbnail_preview
            img
              outline: 2px solid #000000

.slick-slide
  div
    figure
      position: relative
      margin: 0 !important
      img
        border: $grey100 1px solid
        margin: 0
      figcaption
        color: #FAFAFA
        position: absolute
        z-index: 10
        text-align: left
        line-height: 1.6em
        bottom: 0
        background-color: rgba(0, 0, 0, 0.5)
        width: 100%
        padding: 5px 15px
