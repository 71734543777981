@import '../variables'

$faIconAndSeparatorColor: $grey300

.page-content.page-content__no-top-border
  border-top: none !important

.post-direction-based-hero-wrapper
  +container
  width: 100%
  display: block
  background-color: transparent
  margin-top: 64px !important
  margin-bottom: 80px !important
  +tablet
    margin-top: 32px !important
    margin-bottom: 0 !important
  +mobile
    margin-top: 16px !important
    margin-bottom: 0 !important
    padding: 0 $mobileContentPadding
    .content-banner__content-breadcrumb
      +mobile
        margin-top: 0
  .content-banner__content-breadcrumb
    justify-content: left !important

.post-direction-based-hero-wrapper.post__header-hero-left-side
  +all-desktop
    margin-bottom: 0 !important

.post-direction-based-hero
  display: flex
  column-gap: 48px
  width: 100%
  background-color: transparent
  +mobile-and-tablet
    display: block

.post__header-hero-left-side
  .layout__content-with-sidebar
    .content-heading
      display: none

.post__header-hero-left-side,
.post__header-hero-right-side
  .post-direction-based-hero__metadata
    +all-desktop
      width: 45%

    +mobile-and-tablet
      margin-right: unset
      margin-bottom: 36px

.post__header-hero-left-side,
.post__header-hero-right-side
  .post-direction-based-hero__image-wrapper
    +all-desktop
      width: 55%

.post-direction-based-hero__image
  border-radius: 8px
  max-height: 100%
  max-width: 100%
  object-fit: cover

.post__header-hero-left-side
  .post-direction-based-hero
    flex-direction: row-reverse

.post__header-hero-right-side
  .post-direction-based-hero
    align-items: center

.post__header-hero-bottom-side
  .post-direction-based-hero
    flex-direction: column
  .post-direction-based-hero__title
    margin-bottom: 8px
  .post-direction-based-hero__image
    margin-top: 64px
    +mobile
      margin-top: 40px

.post-direction-based-hero__teaser
  font-weight: 400
  font-size: 24px
  line-height: 36px
  letter-spacing: 0.36px
  color: $grey700
  margin-bottom: 16px
  +mobile-and-tablet
    margin-top: 8px
    font-size: 18px
    line-height: 27px
    letter-spacing: -0.45px

.post__header-hero-image
  width: 100vw
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  display: flex
  flex-grow: 1

  .post__header-hero-image-bg-backdrop
    display: none
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)
    opacity: 0.8
    width: 100vw
    position: absolute
    top: 88px
    height: 312px
    +tablet
      top: 15px
      height: 285px
    +mobile
      top: 0px
      height: 200px

  .content
    margin-bottom: 32px
    background: transparent
    z-index: 1

    +mobile
      margin-bottom: 24px

    .content-banner-restricted-width
      margin: auto
      text-align: center

      .content-banner__content-breadcrumb
        margin-bottom: 0px

        li
          float: none
          a
            font-size: 14px
            line-height: 20px

        li+li
          &:before
            padding-left: 5px
            padding-right: 5px

      .content-banner__body
        display: block

      .content-banner__title
        line-height: 120%
        letter-spacing: -1px

    .content-heading__author-bio-and-meta-info,
    .content-heading__primary
      justify-content: center
      border: none
      padding-top: 40px
      padding-bottom: 0
      +mobile
        display: none
      select
        background-color: transparent !important
        border: none

        option
          background-color: transparent !important

      .content-heading__item
        padding-right: 0px
        margin-right: 0px
        padding-left: 10px
        justify-content: center
        &:first-child
          padding-left: 0px
        +tablet
          padding-left: 0px
        .content-author__image
          width: 30px
          height: 30px
          margin: 0
        .content-heading__value
          font-family: $bodyFont
          font-size: 14px

      .content-heading__secondary-social-expanded
        width: fit-content

      .content-heading__secondary-social
        width: auto

        .social-share-links__count--share
          +tablet
            display: inline

        .social-share-links__count--share
          font-weight: normal
          font-size: 14px
          line-height: 20px
          vertical-align: text-bottom

      .content-heading__separator
        &:before
          content: "|"

.post__header-hero-image-behind-text
  height: 400px
  +tablet
    height: 300px
  +mobile
    height: 200px

  .post__header-hero-image-bg-backdrop
    display: block

  .content
    margin-top: auto
    margin-bottom: 32px
    .content-banner-restricted-width
      .content-banner__content-breadcrumb
        li
          a
            color: $postHeaderHeroImageTextColor
            &:hover
              text-decoration: underline
        li+li
          &:before
            color: $postHeaderHeroImageTextColor

      .content-banner__title
        color: $postHeaderHeroImageTextColor

    .content-heading__author-bio-and-meta-info,
    .content-heading__primary
      color: $postHeaderHeroImageTextColor

      a, span
        color: $postHeaderHeroImageTextColor !important
      a
        font-weight: 800
        &:hover
          text-decoration: underline
      select
        color: $postHeaderHeroImageTextColor !important
        font-weight: 800

      .content-heading__secondary-social
        .fa
          color: $faIconAndSeparatorColor !important

        .social-share-links__count--share
          color: $postHeaderHeroImageTextColor !important

      .content-heading__separator
        &:before
          content: "|"
          color: $faIconAndSeparatorColor

.post__header-hero-image-below-text
  .content
    padding-top: 0px
    +mobile
      margin-bottom: 0px
    .content-banner-restricted-width
      .content-banner__content-breadcrumb
        margin-bottom: 15px

    .content-heading__author-bio-and-meta-info,
    .content-heading__primary
      .content-heading__separator
        &:before
          content: "|"
          opacity: 0.5

.hero-image-below-title
  margin: auto
  width: 100%
  background-size: cover
  +wide
    max-width: $twelve-columns-wide
    height: 645px
  +desktop
    max-width: $nine-columns-wide
    height: 480px
  +tablet
    width: 100vw
    margin: auto calc( (100vw - $six-columns-wide) * -1 / 2 )
    height: 340px
  +mobile
    width: 100vw
    margin: auto calc( (100vw - 100%) * -1 / 2 ) auto
    height: 210px

.hero-image-below-title-non-mobile
  display: block
  +mobile
    display: none

.hero-image-below-title-mobile
  display: none
  +mobile
    display: block

.drop
  z-index: 1

article.header-hero-image-post
  margin-top: 0px
  +mobile
    margin-top: 24px
  .content-heading__secondary
    +non-mobile
      padding-top: 0px
  .content-heading__author-bio-and-meta-info,
  .content-heading__primary
    display: none
    +mobile
      display: block
      .content-heading__item
        justify-content: center

article.post__header-hero-right-side
  .content-heading__secondary,
  .content-heading__author-bio-and-meta-info,
  .content-heading__primary
    display: none
