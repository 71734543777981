@import '../variables'

$stripedTableBorderColor              : $grey100
$stripedTableHeaderFontColor          : #89898C
$stripedTableBodyFontColor            : $grey700

table:not(.rouge-line-table)
  border: 1px solid $stripedTableBorderColor
  border-radius: 4px
  border-spacing: 0px
  border-collapse: unset !important
  tr
    color: $stripedTableBodyFontColor
    font-size: 18px
    &:nth-child(even)
      background-color: $lightestGrey
    td:not(.rouge-gutter):not(.rouge-code),
    th
      border: none !important
      padding: 10px !important
  thead ~ tbody
    tr
      background-color: transparent !important
      &:nth-child(odd)
        background-color: $lightestGrey !important
      &:first-child
        font-size: 18px
        color: $stripedTableBodyFontColor
        td,
        th
          border-bottom: none !important
  thead
    th
      border-bottom: 1px solid $stripedTableBorderColor !important
      font-size: 14px
      font-weight: 700
      color: $mediumGrey
