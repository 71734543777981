@import '../variables'
@import '../mixins/block-button'
@import '../mixins/three-column-card'
@import '../mixins/hollow-button'

.market-highlight
  background: $grey25
  width: 100%
  padding: 30px 0
  text-align: center
  // It's not important enough to display on mobile.
  +mobile
    display: none

.market-highlight--padded-bottom
  padding: 30px 0 80px 0

.market-highlight__heading
  margin: auto
  max-width: 80%
  +wide
    max-width: 850px

.market-highlight__intro
  text-transform: uppercase
  color: $grey300
  margin-bottom: 10px
  font-weight: bold
  font-size: 18px

.market-highlight__title
  font-size: 30px
  line-height: 1.4
  padding: 16px 0

.market-highlight__cta
  margin-top: 30px
  font-weight: bold
  a
    @include hollow-button(#82B541)

.market-highlight__cards
  @include container

.market-highlight__card
  @include three-column-full-width-card
  +desktop
    &:nth-child(n+4)
      display: none
  +tablet
    &:nth-child(n+3)
      display: none

  background: $white
  border: solid 1px $borderColor
  border-radius: 4px
  text-align: left
  a
    color: $bodyTextColor

.market-highlight__card-image
  width: 100%
  height: 170px

.market-highlight__card-content
  padding: 10px

.market-highlight__card-title
  font-weight: bold

.market-highlight__card-description
  font-size: 14px
