@import '../variables'

.rouge-syntax-highlight
  overflow: auto
  border-radius: 8px

.rouge-line-table
  border-collapse: separate !important
  border-spacing: 0 !important
  background: #151515 !important
  padding: 15px 5px
  margin: 0 !important
  direction: ltr
  pre
    margin: 0
    font-size: 14px
    span
      white-space: nowrap
  tbody
    background: #151515 !important
    border-collapse: separate !important
    vertical-align: baseline
  tr
    line-height: initial

.rouge-gutter
  width: 1%
  border-top: none !important
  border-bottom: none !important
  border-left: none !important
  border-right: 1px solid $grey700 !important
  font-size: 14px
  color: #afafaf
  padding: 0 8px 0 8px !important
  text-align: right !important

.rouge-code
  color: #ccc
  border: none !important
  padding: 0 0 0 16px !important

.highlight-line
  .rouge-gutter
    background-color: #434343 !important
    color: $white !important
  .rouge-code
    background-color: #2c2c2c !important
    *
      background-color: inherit !important