@import '../mixins/responsive'
@import '../mixins/table_of_contents'
@import '../variables'

.header-hero-image-post
  .post-table-of-content__container
    .post-table-of-content__container-sticky
      margin-top: 45px

.scroll-up
  .post-table-of-content__container
    .floating-toc__list
      max-height: calc(100vh - 300px)

.post-table-of-content__container
  .post-table-of-content__header-title,
  .floating-toc__item
    padding-left: 0

  .floating-toc__list
    overflow-y: auto
    overflow-x: hidden
    max-height: calc(100vh - 200px)
    padding: 0px
    list-style-type: none
    font-size: $base-min-font-size

  .floating-toc__item
    margin-bottom: 10px
    position: relative
    a
      +table-of-contents-title-style

  .floating-toc__item.floating-toc__highlighted
    a
      +table-of-contents-highlighted-title-style
    &::before
      +table-of-contents-highlighter-style
      left: 0px
      top: 10px
    +mini-wide
      padding-left: 10px
    +wide
      padding-left: 12px

  .post-table-of-content__expand-btn
    transition: all .75s ease-out
    position: fixed
    right: -200px
    top: 16px
    cursor: pointer
    font-weight: 700
    font-size: $base-min-font-size
    color: $newBodyTextColor
    line-height: 150%
    letter-spacing: -0.154px
    padding: 12.5px 16px
    background: $white
    border-radius: 8px
    border: 1px solid $postBorderColor

  &.post-table-of-content__collapsed
    left: 100vw
    transition: all 1s ease-out
    .post-table-of-content__container-sticky
      transition: all 1s ease-out
      opacity: 0
    &.sticky-block--floating
      .post-table-of-content__expand-btn
        right: 16px
        transition: all .4s ease-in

  .fa-list
    margin-left: 12px

.post-table-of-content__container
  display: none
  padding-left: 10px
  transition: all 1s ease-out
  +mini-wide
    display: block
    left: 910px
  +wide
    display: block
    left: calc((100% + 875px) / 2)

  top: 0
  position: absolute
  word-break: break-word
  .post-table-of-content__container-sticky
    min-width: 210px
    background-color: $white
    height: 100vh
    padding-top: 25px
    margin-top: 150px
    +mini-wide
      padding-left: 14px
      padding-right: 14px
    +wide
      width: calc((100vw - 850px) / 2 - 22px)
      padding-left: 24px
      padding-right: 24px
    .post-table-of-content__header-title
      font-weight: 600
      font-size: $base-min-font-size
      color: $newBodyTextColor
    .fa-list
      margin-left: 8px
    .scroll-to-target
      display: none
  &.sticky-block--pin-top
    .post-table-of-content__container-sticky
      position: absolute
      top: 0
  &.sticky-block--pin-bottom
    .post-table-of-content__container-sticky
      position: absolute
      bottom: 0
      display: none
  &.sticky-block--floating
    .post-table-of-content__container-sticky
      position: fixed
      top: 0px
      border-left: 1px solid $postBorderColor
      margin-top: 0px
      .scroll-to-target
        display: flex
        position: fixed
        padding: 0px
        bottom: 25px
        .scroll-to-target__btn-label
          margin-right: 8px

  .fa.post-table-of-content__collapse-btn
    position: absolute
    margin-top: 1px
    transform: rotate(270deg)
    left: -12px
    display: none
    border: 1px solid $postBorderColor
    border-radius: 24px
    padding: 5px 6px
    cursor: pointer
    background-color: $white
    font-size: 12px
    color: $newBodyTextColor

  &.sticky-block--floating
    .fa.post-table-of-content__collapse-btn
      display: inline-block
