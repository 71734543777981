@import '../variables'

.call-to-action-btn-primary
  padding: 8px 16px
  border-radius: 4px
  background-color: $callToActionButtonBackground
  color: $white
  &:hover, &:active, &:focus
    color: $white
    background-color: $callToActionButtonBackgroundHover

.call-to-action-btn-secondary
  color: $callToActionButtonBackground
  font-size: 16px
  span
    padding-left: 6px
  &:hover
    color: $callToActionButtonBackgroundHover
  &:active
    color: #075F7F

.call-to-action-btn
  font-weight: 700
  align-items: center
  border: none
  display: inline-flex
  text-decoration: none
  text-align: center
