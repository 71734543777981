@import '../../variables'

.content-block-extendedtips
  display: flex
  flex-wrap: wrap
  padding-bottom: 8px
  padding-top: 8px
  +non-desktop
    flex-direction: column
    flex-wrap: nowrap

.extended_tips_list-item
  display: flex
  align-items: baseline
  margin-bottom: 20px
  .list-item-index
    height: 24px
    width: 24px
    background-color: #E6F0FF
    color: #036DFF
    border-radius: 50%
    display: inline-block
    text-align: center
    font-size: 14px
    min-height: fit-content
    padding: 0
    line-height: 170%
    margin-right: 25px
    font-weight: 600
    flex-basis: 6.5%
  .content-block-contenttextblock
    flex-basis: 130%

.extended-tips__preview-tutorial
  padding-right: 60px
  figure
    margin: 0
    width: 100%
    overflow: hidden
    border-radius: 1rem
  iframe
    padding: 10px
    border-radius: 18px
  +non-desktop
    padding: 0
    margin-bottom: 25px

.extended-tips__tip-info, .extended-tips__preview-tutorial
  display: flex
  align-items: center
  flex-basis: 50%
  flex-wrap: wrap

.extended-tips__tip-info
  padding: 10px
  +all-desktop
    padding: 0
  .extended-tips__head-title
    font-size: 24px
    flex-basis: 100%
    margin-bottom: 10px
  .content-block-button
    background-color: transparent
    margin-top: 30px
    font-size: 16px
    a
      padding: 0px
      color: #035DD9
      &::after
        @extend .fa !optional
        content: "\f061"
        margin-left: 8px

.extended-tips__list
  flex-basis: 100%
  h4
    font-size: 18px
    margin-bottom: 0
  p
    font-size: 14px
    line-height: 21px
    margin-top: 8px
  

