@import '../mixins/responsive'
@import '../mixins/anchor'
@import '../variables'

.content-header
  margin-bottom: 20px
  +desktop
    margin-bottom: 30px
  +mobile
    margin: 10px

.content-header__title-and-meta
  margin-bottom: 1em

.content-header__author-link
   +anchor-bold-style

.content-header__category-link
  font-weight: bold
  font-size: 13px
  text-transform: uppercase
  letter-spacing: 0.1em
  -webkit-font-smoothing: subpixel-antialiased

.content-header__title
  margin: 0 0 10px

.content-header__publication-meta
  display: inline-block
  color: $grey500
  font: italic 14px $bodyFont
  line-height: 1.6em
  margin-bottom: 16px

.content-header__author
  font: normal 14px $bodyFont
  margin-right: 20px

.content-header__author-text
  font-weight: bold
  color: $grey700

.content-header__publication-date
  margin-right: 20px
