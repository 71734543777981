@import '../../mixins/responsive'

.hub-full-width-image-gallery-component
  max-height: 600px

  .hub-full-width-image-gallery-div-container
    max-height: 600px
    min-width: 99vw
    justify-content: center

    .hub-full-width-image-gallery-figure
      height: auto !important
      max-height: 600px !important
      margin-bottom: 0px !important
      margin-top: 0px !important
      width: 20%
      .hub-full-width-image-gallery-image
        max-height: 500px
