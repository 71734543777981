@import '../variables'

.scroll-to-target
  display: flex
  align-items: center
  padding: 12.5px 15px
  width: fit-content
  background-color: $white
  border-radius: 8px
  justify-content: center

  +desktop
    min-height: 46px
    min-width: 46px
  +mobile-and-tablet
    min-height: 46px
    min-width: 46px

  *
    color: $newBodyTextColor
    font-size: $base-min-font-size
    font-weight: 700

  .scroll-to-target__btn-label
    line-height: 150%
    letter-spacing: -0.154px
    margin-right: 12px

  .scroll-to-target__btn-icon
    transform: scale(1, -1)
