@import '../../mixins/responsive'

$maxWidthMobileContainer: calc(100vw - 20px)
$maxWidthTabletContainer: 630px

.content-block-fullwidthsection
  padding-top: 2rem !important
  padding-bottom: 2rem
  padding-left: 1rem
  padding-right: 1rem
  margin-top: 2rem
  margin-bottom: 2rem

  *
    &:last-child
      margin-bottom: 0px !important

  img
    background: none
    max-width: 100% !important

  h2, h3, h4
    margin-top: 0px !important

  figure.embedded-video, figure.final-product--video
    margin-left: auto !important
    margin-right: auto !important

.full_width_post
  .content-block-fullwidthsection
    width: 100vw
    margin-left: calc(-1 * (100vw - 850px) / 2)
    justify-content: center
    padding-left: 0rem
    padding-right: 0rem

    .full-width-section__full-container
      @include container
      margin-left: auto
      margin-right: auto
      +mobile
        max-width: $maxWidthMobileContainer
      +tablet
        max-width: $maxWidthTabletContainer

    .full-width-section__content-container
      max-width: 850px
      margin-left: auto
      margin-right: auto
      +mobile
        max-width: $maxWidthMobileContainer
      +tablet
        max-width: $maxWidthTabletContainer

    +tablet
      margin-left: calc(-1 * (100vw - 630px) / 2)

    +mobile
      margin-left: -10px
