@import '../../variables'

.visual-toc__show-more
  text-align: center
  padding: 5px 10px 5px
  margin: 5px auto
  width: 120px
  border: 1px solid $grey100
  border-radius: 8px
  cursor: pointer
  color: $primaryBlueColor
  font-weight: 600
  font-size: 14px

.visual-toc__divider
  height: 60px
  padding-top: 60px
  margin-top: -55px
  position: relative
  background: linear-gradient(rgba(255, 255, 255, 0), #FFFFFF 70%) 

.visual-toc-list__overflow
  display: flex
  flex-wrap: no-wrap
  +mobile
    flex-wrap: wrap
  .visual-toc-column
    width: 50%
    &:first-child
      margin-right: 50px
    +mobile
      width: 100%
      &:first-child
        margin-right: 0px

.visual-toc-list__unequal-split
  +non-mobile
    display: grid
    grid-template-rows: repeat(5, 1fr)
    grid-template-columns: repeat(2, 1fr)
    grid-auto-flow: column
    grid-column-gap: 40px

.visual-toc-list__equal-split
  +non-mobile
    display: block
    column-count: 2
    grid-column-gap: 40px

    li
      break-inside: avoid-column

ul.visual-toc-list
  font-family: system-ui
  padding: 0
  overflow: hidden
  transition: max-height 0.35s ease-out
  &.show-full
    transition: max-height 0.35s ease-in !important
  &.toc-ordered-list
    list-style: none
    counter-reset: item
    padding-left: 35px
  &.toc-unordered-list
    list-style: disc
  &.visual-toc__thumbnail-theme
    padding-left: 0
  li
    padding: 4px
    counter-increment: item
    padding-right: 12px
    line-height: 1.6em
    position: relative
    .visual-toc__item-thumbnail
      width: 100px
      padding: 0
      border-radius: 5px
      aspect-ratio: 3/2
      object-fit: cover
    .visual-toc__item-thumbnail-link
      display: grid
      grid-template-columns: 100px 1fr
      cursor: pointer
      span.visual-toc__heading-title
        margin-left: 22px
        align-self: center
        font-weight: 600
      img.visual-toc__item-thumbnail
        margin-top: 5px
    &:before
      margin-right: 10px
      content: counter(item)
      background: $grey25
      border-radius: 100%
      color: $grey500
      font-size: 14px
      font-weight: 600
      line-height: 1.8em
      height: 24px
      width: 24px
      text-align: center
      display: inline-block
      position: absolute
      left: -35px
      top: 7px
    &.visual-toc__item-thumbnail-theme
      display: flex
      align-items: center
      position: relative
      padding-left: 0px
      &:before
        position: absolute
        border: 2px solid $white
        left: 85px
        top: 27px

.toc-item-title
  a
    color: $grey700 !important
    text-decoration: none !important
    font-weight: 600 !important
    &:hover,
    &:focus
      text-decoration: underline !important
      text-decoration-color: $grey700 !important

.content-block-tableofcontents:has(> .visual-toc__regular-theme)
  border: 1px solid $grey100
  border-radius: 8px
  padding: 32px 16px 16px 16px

  h2,
  h3,
  h4,
  p
    margin: 0 !important
