@import '../../variables'

.content-block-profilesection
  p
    margin: 0
  .interview-profile-section__content
    display: flex
    flex-wrap: nowrap
    +mobile
      flex-wrap: wrap

    .interview-profile-section__info
      +mobile
        padding-right: 0px
      .interview-profile-section__description
        margin-bottom: 16px
      .interview-profile-section__name
        p, h3
          margin: 0
          font-weight: 700
          font-size: 18px
          color: $grey700
          line-height: 150%
      .content-block-socialgroup
        margin-bottom: 0px

    .interview-profile-section__photo
      margin-right: 24px
      min-width: 150px
      +mobile
        padding: 0px
        margin-bottom: 15px
        margin-right: 0
      img
        width: 160px
        height: auto
        border-radius: 16px
        margin: 0
        padding: 0
        +mobile
          width: 104px
