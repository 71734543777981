@import '../../variables'

.full-width-iamge
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  img
    position: relative
    min-width: 100vw