@import '../variables'
@import '../mixins/responsive'

.content-footer
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 150%
  letter-spacing: -0.154px
  margin: 80px 0px

  +mobile-and-tablet
    margin: 60px 0px

  *
    display: flex
    align-items: center
  
  .content-footer__content-meta-details
    justify-content: space-between
    
    .content-footer__author-details
      color: $grey500

      .content-footer__author-image
        border-radius: 8px
        margin-right: 16px

      .content-footer__author-name
        margin-left: 4px
        a
          color: $grey700
          font-weight: 600

          &:hover
            text-decoration: underline

    .content-footer__additional-details
      height: 42px
      color: $grey700
      font-weight: 600
      line-height: 125%

      .content-footer__feedback-buttons-group
        border: 1px solid $grey100
        border-radius: 6px

        .content-footer__feedback-like-button,
        .content-footer__feedback-dislike-button
          padding: 10px 12px

          .content-footer__feedback-icon-placeholder
            img
              display: none

          .content-footer__feedback-icon-placeholder:not(.feedback-btn__highlight)
            img:first-child
              display: flex

          .content-footer__feedback-icon-placeholder.feedback-btn__highlight
            img:last-child
              display: flex

          &.btn-disable
            opacity: .5
            cursor: auto
        
        .content-footer__feedback-like-button
          border-right: 1px solid $grey100

          > span
            margin-right: 4px

        a
          color: $grey700
          cursor: pointer

          img.feedback-btn__highlight
            path
              fill: $blue600
