@import 'variables'

.layout__sidebar-translate-native-logo
  display: block
  margin: 0 auto
  width: 60%

.layout__sidebar-elements-subscribe-banner-image
  display: block
  border-radius: 4px
  text-align: center
  margin: auto
  margin-bottom: $contentSectionMarginBottom
  max-width: $three-columns-wide
  min-height: 250px
  min-width: 250px
