@import '../variables'

$tagSpacingPixels: 5px
$tagBorderColor: $grey300
$tagHoverBackgroundColor: #F3F3F3

.content-heading
  .content-heading__secondary
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    padding-top:    32px
    .content-heading__secondary-categories-tag
      display: inline-block
      margin-right: 10px
      margin-bottom: 10px
      padding: 0px 8px
      color: $grey500
      border: 1px solid $tagBorderColor
      border-radius: 25px
      white-space: nowrap
      &:hover, &:focus, &:active
        background-color: $tagHoverBackgroundColor
        color: $grey700      
    +mobile
      display: none

  &.content-heading__mobile
    display: none
    margin: 40px 0px -40px
    +mobile
      display: block
    .content-heading__secondary
      border-top: solid 1px $grey50
      display: block
      text-align: center

.full_width_post
  .content-heading__secondary
    justify-content: center
