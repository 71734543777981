@import '../../variables'

.full_width_post
  &:not(.post__floating-table-of-contents)
    .content-block-fullwidthinterviewblockquote
      width: 1000px
      margin-left: -80px
      +mobile-and-tablet
        width: auto
        margin-left: 0px

.content-block-fullwidthinterviewblockquote
  margin-top: 40px !important
  margin-bottom: 40px
  line-height: 2em
  .full-width-interview_quote
    font-weight: 700
    font-size: 24px
    color: $newBodyTextColor
    text-align: center
    line-height: 125%
    letter-spacing: 0.36px
    p
      margin: 0 !important

.full-width-interview_profile__name
  margin-top: 32px
  text-align: center
  font-size: 14px
  line-height: 150%
  letter-spacing: -0.154px

  strong
    line-height: 125%
