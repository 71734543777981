@import '../variables'

@mixin table-of-contents-highlighter-style
  content: ""
  display: block
  width: 5px
  height: 5px
  background: #0085B6
  border-radius: 50%
  position: absolute

@mixin table-of-contents-highlighted-title-style
  color: $newBodyTextColor

@mixin table-of-contents-title-style
  color: $lightGrey

  &:hover,
  &:active,
  &:focus-visible
    color: $newBodyTextColor
