@import '../variables'

.cta-link
  width: 100%
  margin-bottom: -8px
  display: flex
  text-align: center
  .cta-block
    position: relative
    width: 100%
    .cta-top-bar
      height: 60px
      margin-bottom: 20px
    .cta-logo
      margin-bottom: 20px
    .cta-text
      font-family: $bodyFont
      font-weight: 700
      font-size: 38px
      line-height: 1.2em
      color: #000000
      overflow: hidden
      margin-bottom: 20px
      +mobile
        font-size: 24px
    .cta-button
      margin-bottom: 20px
      height: 38px
      .cta-button-text
        height: 38px
        padding: 9px 30px
        border-radius: 6px
        font-weight: 600
        font-size: 16px
        line-height: 19px
        text-align: center
        color: $white
        width: fit-content
        margin: 0 auto
    .cta-mid-bar
      height: 60px
    .cta-terms-and-conditions
      font-size: 14px
      font-weight: 400
      color: #505050

  &:hover,
  &:focus,
  &:active
    .cta-button
      .cta-button-text
        background: #c73152
