@import '../../mixins/responsive'

.content-block-fullwidthimage
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  background-color: transparent !important

  .full-width-image__content-container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    img
      position: relative
      min-width: 100vw
      border-radius: 0px !important
