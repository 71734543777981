@import '../../variables'

$borderColor: $grey100
$embedCoursePrimaryColor: #0085B6
$fontColorSharp: #131419
$fontColorNormal: $grey700
$iconsColor: #89898C

.content-block-embedcourse
  margin-bottom: 30px
  padding: 32px 0px !important
  border-style: solid
  border-color: $borderColor
  border-width: 1px 0px
  display: flex
  h2
    margin-top: 0 !important
    margin-bottom: 10px !important
  +mobile-and-tablet
    flex-direction: column-reverse
  .embed-course-details__wrapper
    flex: auto
    margin-right: 30px
    width: 45%
    +mobile-and-tablet
      width: 100%
      margin-top: 30px
      margin-right: 0px
    .embed-course-summary__wrapper
      letter-spacing: -0.025em
      line-height: 150%
      div
        display: inline
        margin-right: 10px
        font-size: 14px
        color: $fontColorSharp
      .free-course__label
        display: none
        background-color: $envatoGreen
        color: $white
        padding: 3px 15px
        letter-spacing: 1px
        border-radius: 5px
        font-weight: bold
        line-height: normal
        font-size: 12px
      i
        color: $iconsColor
        margin-right: 5px
    .embed-course__title
      font-size: 24px
      color: $fontColorSharp
    .embed-course__button-group
      border-bottom: 1px solid $borderColor
      padding-bottom: 12px
      a
        font-size: 14px !important
        white-space: nowrap
        margin-top: 5px
        display: inline-block
        text-decoration: none
        border-radius: 4px
        padding: 0px 12px
        margin-right: 10px
        border-width: 1px
        color: $fontColorSharp
        background-color: transparent
        border: 1px solid $borderColor
        &.primary
          background-color: $embedCoursePrimaryColor
          border-color: $embedCoursePrimaryColor
          color: $white

    .embed-course__contents
      color: $fontColorNormal
      .embed-course__description
        margin: 16px 0px
        word-break: break-word
      .embed-course__chapters-title-list
        padding: 0px
        list-style: none
        margin-bottom: 0px
        li
          margin-left: 0px
          &:before
            content: '\2713\0020'
            color: $embedCoursePrimaryColor
            margin-right: 7px

  .embed-course-video__wrapper
    width: 55%
    +mobile-and-tablet
      width: 100%
    figure
      margin: 0px !important
      iframe
        border-radius: 8px
        +mobile-and-tablet
          height: 100%
