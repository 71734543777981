.callout-block
  border: 1px solid #E3E9EB
  box-shadow: 1px 4px 8px rgba(24, 25, 30, 0.04)
  border-radius: 4px
  padding: 25px 15px
  display: flex
  margin-bottom: 26px

.callout-message
  padding: 0px 14px
  margin-top: -3px
  align-self: center
  span
    font-size: inherit

.callout-head
  color: #7E7E7E
  font-size: 12px
  margin-left: 15px
  font-weight: bold
  line-height: 1.5em
  text-transform: uppercase

.callout-icon
  width: 33px
  height: 33px

.callout-warn
  content: url('images/warning.svg')

.callout-do
  content: url('images/checkmark.svg')

.callout-dont
  content: url('images/dont.svg')

.callout-info
  content: url('images/info.svg')

.callout-tip
  content: url('images/tip.svg')
