@import '../../mixins/responsive'

$columnGap: 32px
$rowGap: 40px
$columnExtraGap: 40px
$oneColumnWidth: 100%
$twoColumnWidth: 50%
$threeColumnWidth: 33.33%
$fourColumnWidth: 25%
$twoColumnLayout: calc($twoColumnWidth - $columnGap)
$twoColumnLayoutExtraGap: calc($twoColumnWidth - $columnExtraGap)
$threeColumnLayout: calc($threeColumnWidth - $columnGap)
$threeColumnLayoutExtraGap: calc($threeColumnWidth - $columnExtraGap)

.content-block-grid
  .element-row
    &:last-child
      margin-bottom: 0
    &:not(last-child)
      margin-bottom: $rowGap
  .element-col, .content-block-wysi
    & > *:not(last-child)
      margin-bottom: 16px
      margin-top: 0
    & > :last-child
      margin: 0

  margin: 40px 0px 40px 0px
  h1, h2, h3, h4, h5, h6
    margin-top: 0px

.element-row
  display: flex
  flex-wrap: nowrap
  .element-col
    flex-basis: $oneColumnWidth
    flex-grow: 1
  img
    +mobile
      width: 100%
  img, figure
    margin: 0
  .content-block
    margin-bottom: 0
  &.four-column-grid
    flex-wrap: wrap
    +wide
      flex-wrap: nowrap
    &.grid-gap--row-wide,
    &.grid-gap--row-extra-wide
      row-gap: $rowGap
    &.grid-gap--col-wide
      column-gap: 24px
      .element-col
        +tablet
          flex-basis: $twoColumnLayout
        +desktop
          flex-basis: $threeColumnLayout
        +wide
          flex-basis: calc($fourColumnWidth - $columnGap)

    &.grid-gap--col-extra-wide
      column-gap: 24px
      .element-col
        +tablet
          flex-basis: $twoColumnLayoutExtraGap
        +desktop
          flex-basis: $threeColumnLayoutExtraGap
        +wide
          flex-basis: calc($fourColumnWidth - $columnExtraGap)

  &.three-column-grid
    flex-wrap: nowrap
    +mobile
      flex-wrap: wrap
    &.grid-gap--col-wide
      column-gap: $columnGap
      .element-col
        +tablet
          flex-basis: $threeColumnLayout
    &.grid-gap--col-extra-wide
      column-gap: $columnGap
      .element-col
        +tablet
          flex-basis: $threeColumnLayoutExtraGap

  &.two-column-grid
    flex-wrap: nowrap
    +mobile
      flex-wrap: wrap
    &.grid-gap--col-wide
      .element-col
        +tablet
          flex-basis: $twoColumnLayout
    &.grid-gap--col-extra-wide
      .element-col
        +tablet
          flex-basis: $twoColumnLayoutExtraGap

.grid-gap--row-wide,
.grid-gap--row-extra-wide
  row-gap: $rowGap

.grid-gap--col-wide
  column-gap: $columnGap
  +mobile
    column-gap: 0

.grid-gap--col-extra-wide
  column-gap: $rowGap
  +mobile
    column-gap: 0

.grid-container__caption
  margin-top: 10px
  text-align: center
  font-size: 14px
  color: #5A5A5E

.grid-image__no-padding
  padding: 0 !important
  margin: 0 !important

.content-block-grid
  &.grid-item-list
    +wide
      width: 1290px
    .element-row
      display: grid
      column-gap: 24px
      row-gap: $columnExtraGap
      +mobile
        row-gap: 24px
      grid-template-columns: repeat(4, 1fr)
      +desktop
        grid-template-columns: repeat(3, 1fr)
      +tablet
        grid-template-columns: repeat(2, 1fr)
      +mobile
        grid-template-columns: repeat(1, 1fr)
