@use "sass:math"
@import '../variables'
@import '../mixins/responsive'
@import '../mixins/clearfix'
@import '../mixins/post-card'

.roundup-block__posts,
.roundup-block__contents
  margin: 20px 0 40px
  padding: 5px
  +all-desktop
    margin-left: -15px
  +mobile-and-tablet
    padding: 0
  +clearfix

.element-col
  .roundup-block__content
    flex-grow: 1

.roundup-block--list
  display: flex
  flex-wrap: wrap
  list-style-type: none
  .roundup-block__post,
  .roundup-block__content
    flex-basis: 100%
    margin-bottom: 15px
    padding: 0
    padding-right: 5px
    +tablet
      flex-basis: 50%
    +all-desktop
      flex-basis: 50%
      padding: 7px
      padding-bottom: 0px
      &:hover
        box-shadow: 0px 2px 10px rgb(0 0 0 / 15%)
        border-radius: 5px
    .roundup-block__content-container
      display: flex
      .roundup-block__preview
        flex-basis: 30%
        margin-right: 20px
        +tablet
          flex-basis: 50%
          margin-right: 15px
        +all-desktop
          flex-basis: 40%
        .roundup-block__preview-image
          width: 100%
          outline: none
          background: none
          padding: 0
          border-radius: 5px
      .roundup-block__content-meta
        flex-basis: 70%
        margin-top: 2px
        +tablet
          flex-basis: 75%
        +all-desktop
          flex-basis: 60%
        .roundup-block__content-title
          font: 600 14px/1.2em system-ui,Roboto,Arial,sans-serif
          color: $grey700
        .roundup-block__author-info
          display: flex
          flex-wrap: nowrap
          font-size: 12px
          margin-top: 0px
          +tablet
            margin-top: 5px
          +all-desktop
            margin-top: 10px
          .roundup-block__author-profile-image
            margin-right: 7px
            display: none
            .roundup-block__author-image
              width: 35px
              height: 35px
              margin: 0
              outline: 0
              padding: 0
              border-radius: 50%
          .roundup-block__author-meta
            line-height: 1.5em
            .roundup-block__author-name
              color: $grey700
            .roundup-block__published-date
              color: $grey500
              +tablet
                display: none

.roundup-block__posts.roundup-block--card,
.roundup-block__contents.roundup-block--card
  .posts__post-preview-image--regular,
  .posts__post-author_photo
    padding: 1px
    outline: none
    margin: 0
    background: transparent

  .posts__post-author_photo
    +post-card-author-photo

  .posts__post-preview-image--regular
    +post-card-image

  a.roundup-block__post-link,
  a.roundup-block__content-link
    color: $grey700
    display: block
    &:after
      content: ''
      display: table
      clear: both
    &:hover, &:active, &:focus
      color: darken($grey700, 5%)
      text-decoration: none

  img.roundup-block__preview-image
    border: none
    border-radius: 4px 4px 0 0
    height: 207px
    max-height: 207px
    padding: 0

  .roundup-block__primary-category
    font: bold 10px/1.6em $bodyFont
    text-transform: uppercase
    letter-spacing: 0.1em
    display: block
    -webkit-font-smoothing: subpixel-antialiased
    margin-bottom: 10px
    +topic-colors
      color: $topic-color
    &:hover, &:active, &:focus
      +topic-colors
        color: darken($dark-topic-color, 5%)

  .roundup-block__post-title,
  .roundup-block__content-title
    display: block
    font: bold 19px/1.2em $bodyFont
    color: $grey700

  .roundup-block__author
    font: normal 12px/1.6em $bodyFont
    color: $grey500
    margin-top: 10px
