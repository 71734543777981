@use "sass:math"
@import '../variables'
@import '../mixins/responsive'
@import '../mixins/topic-colors'
@import '../mixins/clearfix'
@import '../mixins/post-card'
@import '../mixins/h2'
@import '../mixins/h3'
@import '../mixins/h4'
@import '../mixins/h5'
@import '../mixins/paragraph'
@import '../mixins/anchor'
@import './roundup_block'

.post-body__translation
  margin: 20px 0
  +wide
    display: none
  +desktop
    display: none

.post-body__sponsored-content
  +highlighted-box
  line-height: 1.6em

.post-body__translated-by
  +highlighted-box

////////////////////////////////////////////////////////////////////////////////////////
// Learning Guides navigation

.post-body__series-navigation
  +highlighted-box
  a:not([class])
   +anchor-default-link-style

.post-body__series-navigation-item
  margin-top: 10px

.post-body__series-navigation-next-link
  +anchor-default-link-style

.post-body__series-navigation-previous-link:before,
.post-body__series-navigation-next-link:before
  margin-right: 10px
  font-family: $font-awesome-family
  font-weight: $font-awesome-solid
  font-size: 14px

.post-body__series-navigation-previous-link
  content: $fa-backward

.post-body__series-navigation-next-link:before
  content: $fa-forward

////////////////////////////////////////////////////////////////////////////////////////
// Post Content

.summary-text
  color: $newBodyTextColor
  font-size: 24px
  font-weight: 500
  line-height: 32px
  margin-top: 40px
  margin-bottom: 64px !important

  code
    font-size: .8em !important

.post-body__content, .syllabus__content, .yt-course__main-content
  @include paragraph
  a:not([class])
    +anchor-default-link-style

        
  & > *:first-child
    margin-top: 0
    padding-top: 0

  h2:first-of-type
    margin-top: 64px
    @include h2-style

  figure, img, embed, video, object, iframe
    max-width: 100%
    text-align: center

  audio, video
    width: 100%

  figure:not([class])
    iframe
      width: 100%
      min-height: 50vh

  figure.embedded-video, figure.final-product--video
    position: relative
    padding-bottom: min(56.25%, 478.13px) // 16:9
    max-width: 850px
    //padding-top: 25px
    iframe, object, embed
      top: 0
      left: 0
      width: 100%
      height: 100%
      position: absolute

  figure.final-product--video
    margin-bottom: 80px

  img
    // This *may* have adverse effect if applied to object/iframe. (?)
    height: auto
    margin: 1px /* Compensate for outline */
    background: $postImageBackgroundColor
    border-radius: 8px

  code, tt, pre
    font-family: $sfMonoFont

  pre
    overflow: hidden

  hr
    display: none

  h2, h3, h4, h5, h6, .question
    margin-bottom: 0

  .question
    line-height: 1.6em

  h2
    margin: 64px 0 26px 0
    @include h2-style

    & > span.sectionnum
      color: lighten($bodyTextColor, 50%)

  h3
    margin: 40px 0 20px 0
    @include h3-style

  h4, .question
    @include h4-style
    margin-bottom: 13px
    margin-top: 40px
    // Interview question headings, e.g. "Q. Why do you wear your briefs outside your pants, Superman?"
    & > span
      color: $grey500
      &:after
        content: ". "

  h5
    @include h5-style

  h6
    font-size: 12px
    text-transform: uppercase
    letter-spacing: 0.1em

  h3 + *, h3 + * > *:first-child
    margin-top: 0.3em

  p.question
    &:before
      content: 'Q. '
      color: $grey500

  blockquote
    border-left: 4px solid $primaryBlueColor
    padding: 16px 0px 16px 32px
    margin: 0px 0px 40px
    font-size: 24px
    line-height: 150%
    letter-spacing: 0.36px

  code
    border: solid 1px $borderColor
    padding: 2px 4px
    background: $secondaryBackgroundColor
    font-size: 16px
    border-radius: 3px
    overflow-wrap: anywhere

  figure
    margin: 24px 0 16px 0

  figcaption
    font-size: 14px
    color: $metaColor

  .hex
    background: #d7dee5
    padding: 0.3em 0.5em 0.15em
    font-family: $sfMonoFont
    font-size: 14px
    color: $bodyTextColor
    border-radius: 3px

  p
    text-indent: 0
    margin-top: 16px
    margin-bottom: 24px

  // Workaround for polldaddy generated crap
  .PDS_Poll .pds-box
    +mobile
      width: 100%

  .sponsored-shortcode,
  .review-shortcode,
  .disclaimer-shortcode,
  .republished-shortcode,
  .changed-shortcode,
  .videodownload-shortcode,
  .related-shortcode,
  .tip-shortcode
    +highlighted-box

  ul.webroundup
    list-style: none
    margin: 0
    padding: 0

    li
      margin-bottom: 30px
      +non-mobile
        position: relative
        padding-left: 200px
        min-height: 170px

        img
          position: absolute
          left: 0
          top: 5px
          width: 170px
          height: auto
      +mobile
        h4
          margin-top: 0

  table
    border-collapse: collapse
    width: 100%
    margin: 0 0 26px 0

  td,
  th
    border: solid 1px $linkSecondaryColor
    text-align: left
    padding: 5px 10px

  // Fixes CSS issues for quiz widgets, like:
  // http://code.lvh.me:3000/articles/mobiletuts-quiz-phonegap-fundamentals--mobile-9856
  #quiz-container *
    box-sizing: content-box
    line-height: initial

  // Embedded tweets, !important to override inline styles
  .twitter-tweet
    margin: 30px 0 !important

.yt-course__play-btn
  img
    +mobile
      max-width: calc(100% - 2px)

////////////////////////////////////////////////////////////////////////////////////////
// Post Body RTL overrides

.post-body--rtl
  .post-body__content
    direction: rtl

    code, tt, pre
      unicode-bidi: embed
      direction: ltr
      font-family: $sfMonoFont

    .syntaxhighlighter
      direction: ltr

.post__article-top
  +mobile
    display: none

.post__inarticle-ad-template
  +mobile
    display: none !important

  &.is-hidden
    display: none

.post__inarticle-ad-template-mobile
  +non-mobile
    display: none !important

  &.is-hidden
    display: none

.post__article-ad
  +mobile
    display: none

.post__article-ad-mobile
  +non-mobile
    display: none

.post__sticky-sky-ads
  .post__article-top,
  .post__inarticle-ad-template,
  .post__fullwidth-inarticle-ad-template
    +wide
      display: none

.sticky-ad-sky-left.tether-pinned-top,
.sticky-ad-sky-right.tether-pinned-top
  top: calc( 100vh / 2 - 313px ) !important

.layout__sidebar-ad-1,
.layout__sidebar-ad-2,
.layout__sidebar-ad-3
  +mobile
    display: none

.layout__sidebar-ad-mobile
  +non-mobile
    display: none

.video-preview-image
  cursor: pointer
  position: absolute
  width: 100%
  height: 100%
  background-size: cover
  &.youtube
    &:hover
      .play-button-bg
        fill: #f00
    .play-button
      position: absolute
      top: 0
      left: calc(50% - 34px)

    .video-preview-image__ytv-heading
      position: absolute
      width: 100%
      padding: 5px 12px 0
      height: 60px
      display: flex
      align-items: center
      .video-preview-image__ytv-heading-gradient-top
        height: 110px
        padding-bottom: 50px
        top: 1px
        left: 1px
        z-index: 25
        background-position: top
        width: 100%
        position: absolute
        background-repeat: repeat-x
        opacity: 0.5
        background-image: linear-gradient(black, transparent)
        transition: opacity .25s cubic-bezier(0.0,0.0,0.2,1)
        pointer-events: none
      .video-preview-image__ytv-heading-channel-logo-link
        height: 40px
        width: 40px
        border-radius: 50%
        background-size: cover
        z-index: 26
      .video-preview-image__ytv-heading-title-link
        color: $white
        margin-left: 10px
        font-family: $bodyFont
        font-size: 18px
        line-height: 1.3
        -webkit-tap-highlight-color: rgba(0,0,0,0)
        text-overflow: ellipsis
        overflow: hidden
        width: 75%
        white-space: nowrap
        text-align: left
        z-index: 26

  &.wistia
    .play-button
      fill: rgb(255, 255, 255)
      height: 80px
      left: 0px
      stroke-width: 0px
      top: calc(50% - 40px)
      width: 100%
      position: absolute
      .play-button-bg
        +topic-colors
          fill: $topic-color
  img
    object-fit: cover
    width: 100%
    padding: 0px
    height: 100%

.resized-image
  display: none !important
  &.resized-image-desktop
    +all-desktop
      display: inline !important
  &.resized-image-tablet
    +tablet
      display: inline !important
  &.resized-image-mobile
    +mobile
      display: inline !important

.tutorial_image
  text-align: center

.content-author
  border: 0px
