@import '../variables'

.post__floating-table-of-contents
  .post-on-scroll-floating-buttons-group__container
    &.sticky-block--floating
      transition: all .4s ease-in
      right: 16px
      &.hide
        right: -200px
        transition: all .75s ease-out
        +desktop
          right: 16px !important
        +mini-wide
          right: -200px !important
        +mobile-and-tablet
          right: 16px !important

    &.sticky-block--pin-top,
    &.sticky-block--pin-bottom
      +mini-wide
        opacity: 1
        right: -200px
        transition: all 2s ease-out
      +wide
        opacity: 1
        right: -200px
        transition: all 2s ease-out

.post-on-scroll-floating-buttons-group__container
  transition: all 1s ease-out
  opacity: 0
  z-index: -1
  border: 1px solid $postBorderColor
  border-radius: 8px
  position: fixed
  bottom: 16px
  right: 16px
  background-color: $white
  &.sticky-block--floating
    transition: all 1s ease-in
    opacity: 1
    z-index: 2147483646

  *
    color: $newBodyTextColor
    font-size: $base-min-font-size
    font-weight: 700

  .post-on-scroll-floating-button
    display: flex
    align-items: center
    padding: 12.5px 15px

    .post-on-scroll-floating-button__label
      line-height: 150%
      letter-spacing: -0.154px
      margin-right: 12px

  span[class*=-label],
  span[class*=__label]
    display: none
    +mini-wide
      display: block
    +wide
      display: block
