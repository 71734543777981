@import '../mixins/topic-colors'

.content-banner-restricted-width
  &.full_width_post
    max-width: 100%

    .content-banner__content-breadcrumb,
    .content-heading__secondary
      display: flex
      justify-content: center
      flex-wrap: wrap

    .content-banner__content
      margin: auto
      margin-top: 16px
      max-width: 850px
      text-align: center

      .content-banner__icon,
      .content-banner__body
        display: block

      .content-banner__title-icon
        display: inline !important

.post
  &.full_width_post
    justify-content: center
    display: flex

    .layout__content-with-sidebar
      width: 100% !important
      max-width: 850px !important

      .content-space-info__cards.cards_container
        display: flex
        justify-content: center

      iframe
        display: block
        margin: auto auto 20px
