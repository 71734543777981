@import '../variables'

@mixin hollow-button($color: $primaryBlueColor, $border_color: $color)
  display: inline-block
  color: $color
  border-radius: 4px
  border: 2px $border_color solid
  padding: 10px 40px
  vertical-align: middle
  text-align: center
  cursor: pointer
  white-space: nowrap
  &:hover, &:active, &:focus
    color: darken($color, 5%)
